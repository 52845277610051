










































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { faPhoneAlt } from '@fortawesome/pro-regular-svg-icons';
import IsiContent from './isi-content.vue';

@Component<Footer>({
    components: {
        'isi-content': IsiContent,
    },
    icons: {
        faPhoneAlt,
    },
})
export default class Footer extends Vue {
    @Prop()
    public brkPoints!: object;
    @Prop()
    public isExpanded!: boolean;
    @Prop()
    public isStatic!: boolean;
}
