import { Mutation, Action } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { Resolve } from 'vue-di';
import { EdgeDocumentsApi } from '@trialcard/apigateway.client/edgeDocumentsApi';
import EnrollmentModule from '~/store/enrollment';

@InjectModule({ stateFactory: true }, module)
export default class DocumentModule extends InjectVuexModule {
    @Resolve
    enrollment!: EnrollmentModule;

    @Resolve
    edgeDocumentsApi!: EdgeDocumentsApi;

    public hasDocument = false;

    @Action({ rawError: true })
    clear() {
        this.updateDocumentStatus(false);
    }

    @Action({ rawError: true })
    setDocumentStatus(newDocumentStatus: boolean) {
        this.hasDocument = newDocumentStatus;
    }

    @Action({ rawError: true })
    public async fetchDocument() {
        const document = await this.edgeDocumentsApi.fillablePdfGenerateFillablePdf({
            fileUrl: window.location.href.replace('thank-you', 'Aimmune_Web_Offer_v9.pdf'),
            outputFileName: 'copay-card',
            fileData: [{ name: 'memberNumber', value: this.enrollment.memberNumber || '' }],
        });
        return document;
    }

    @Mutation
    updateDocumentStatus(newDocumentStatus: boolean) {
        this.setDocumentStatus(newDocumentStatus);
    }
}
