














































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component<Header>({})
export default class Header extends Vue {}
