













































































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { faPhoneAlt } from '@fortawesome/pro-regular-svg-icons';

@Component<IsiContent>({
    props: {
        position: String,
        isCollapsed: Boolean,
    },
    icons: {
        faPhoneAlt,
    },
})
export default class IsiContent extends Vue {
    @Prop()
    public brkPoints!: object;
}
