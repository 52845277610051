export class Settings {
    public constructor(settings: any) {
        Object.assign(this, settings);
    }
}
export interface Settings {
    base: string;
    url(url: string): string;
    name: string;
    author: string;
    description: string;
    minDate: string;
    mainMenu: Array<{ icon: [string, string]; title: string; to: string; exact: boolean }>;
    errorPages: {
        default: { icon: [string, string]; title: string; message: string; statusCode: number };
        [code: string]: { icon: [string, string]; title: string; message: string; statusCode: number };
    };
    cardTypeQuestionId: string;
    cardTypeAnswerId: string;
    programId: string;
    surveyId: string;
    questionId1: string;
    questionId2: string;
    questionId3: string;
    questionId4: string;
    questionId5: string;
    questionId6: string;
    questionId7: string;
    questionId8: string;
    answerId1Yes: string;
    answerId1No: string;
    answerId2Yes: string;
    answerId2No: string;
    answerId3Yes: string;
    answerId3No: string;
    answerId4Yes: string;
    answerId4No: string;
    answerId5Yes: string;
    answerId5No: string;
    answerId6Yes: string;
    answerId6No: string;
    answerId7Yes: string;
    answerId7No: string;
    answerId8Yes: string;
    answerId8No: string;
    lockEnabled: string;
    lockPassword: string;
}
