var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inner-footer"},[_c('div',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],style:({ 'min-height': (_vm.aboveTheFold ? _vm.placeHolder.h : 0) + 'px' }),attrs:{"id":"footer-placeholder","color":"transparent"}}),_vm._v(" "),(_vm.iri.visibility)?_c('div',{class:{ 'fixed-footer': _vm.aboveTheFold, 'static-footer': !_vm.aboveTheFold, expanded: _vm.isExpanded },attrs:{"id":"main-footer"}},[_c('v-flex',{staticClass:"relative",attrs:{"xs12":""}},[_c('v-flex',{staticClass:"collapsed-iri",attrs:{"id":"peeking-iri"}},[_c('v-row',{staticClass:"pt-0 pb-0",attrs:{"id":"see-more","justify":"center"}},[_c('v-col',{class:{
                            'pl-6': _vm.brkPoints['brk-md-and-down'],
                            'pr-6': _vm.brkPoints['brk-md-and-down'],
                            'pt-0': true,
                            'pb-0': true,
                        },attrs:{"cols":"12","lg":"8","xl":"8"}},[_c('v-row',{class:{
                                'pt-0': true,
                                'pb-0': true,
                                'pl-4': _vm.brkPoints['brk-sm-and-down'],
                            }},[_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12","md":"9"}},[_c('h5',{staticClass:"white--text"},[_vm._v("INDICATION FOR "+_vm._s(_vm.$settings.drugName)+" & IMPORTANT SAFETY INFORMATION")])]),_vm._v(" "),_c('v-col',{staticClass:"pt-2 pb-2",attrs:{"cols":"12","md":"3"}},[_c('div',{class:{
                                        'float-right': _vm.brkPoints['brk-md-and-up'],
                                        'see-more-container': true,
                                    },on:{"click":_vm.toggleisExpanded}},[_c('h5',{staticClass:"white--text see-more-text"},[_vm._v(_vm._s(_vm.isExpanded ? 'CLOSE' : 'SEE MORE'))]),_vm._v(" "),_c('v-btn',{class:{ 'fore-front': true, 'white--text': true },attrs:{"fab":"","color":"green","right":"","x-small":""}},[_c('fa-icon',{attrs:{"size":"2x","icon":_vm.isExpanded ? _vm.$icons.faMinus : _vm.$icons.faPlus}})],1)],1)])],1)],1)],1),_vm._v(" "),_c('Footer',{attrs:{"is-expanded":_vm.isExpanded,"brk-points":_vm.brkPoints,"is-static":!_vm.aboveTheFold}})],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }