




















































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component<IRIInfo>({})
export default class IRIInfo extends Vue {}
