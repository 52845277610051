import { Mutation, Action } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { Resolve } from 'vue-di';
import { SurveySessionValueModel } from '@trialcard/apigateway.models';
import SurveyModule from './survey';
import { Settings } from '~/services/settings';

export interface IEligibilityQuestions {
    surveySessionId: string;
    questionId: number;
    answerId: number;
}
@InjectModule({ stateFactory: true }, module)
export default class EligibilityModule extends InjectVuexModule {
    @Resolve
    public settings!: Settings;

    @Resolve
    public survey!: SurveyModule;

    public eligibilityData = {} as IEligibilityData;
    public eligibilityRequestData = [] as SurveySessionValueModel[];
    private defaultFormData = {
        isPrescribed: null as boolean | null,
        notEnrolled: null as boolean | null,
        isREMS: null as boolean | null,
        isOfAge: null as boolean | null,
        isOverEighteen: null as boolean | null,
        doseCompletedUnderEighteen: null as boolean | null,
        doesAgree: null as boolean | null,
    };
    public formData = Object.assign({}, this.defaultFormData);

    @Action({ rawError: true })
    public setEligibility(newEligibilityData: IEligibilityData) {
        this.updateEligibilityData(newEligibilityData);

        const newEligibilityRequestData = Object.entries(newEligibilityData).map(question => question[1]);
        this.updateEligibilityRequestData(newEligibilityRequestData);
    }

    @Action({ rawError: true })
    public async fetchSurveySession() {
        await this.survey.fetchSurveySession(this.eligibilityRequestData);
    }

    @Action({ rawError: true })
    public clear() {
        this.clearEligibilityData();
        this.clearEligibilityRequestData();
        this.clearFormData();
    }

    @Mutation
    updateEligibilityData(newEligibilityData: IEligibilityData) {
        this.eligibilityData = { ...this.eligibilityData, ...newEligibilityData };
    }

    @Mutation
    updateEligibilityRequestData(newEligibilityRequestData: SurveySessionValueModel[]) {
        this.eligibilityRequestData = [...this.eligibilityRequestData, ...newEligibilityRequestData];
    }

    @Mutation
    updateFormData(newFormData: typeof EligibilityModule.prototype.defaultFormData) {
        this.formData = Object.assign(this.formData, newFormData);
    }

    @Mutation
    clearEligibilityData() {
        this.eligibilityData = {};
    }

    @Mutation
    clearEligibilityRequestData() {
        this.eligibilityRequestData = [];
    }

    @Mutation
    clearFormData() {
        this.formData = Object.assign({}, this.defaultFormData);
    }
}

export interface IEligibilityData {
    question1?: SurveySessionValueModel;
    question2?: SurveySessionValueModel;
    question3?: SurveySessionValueModel;
    question4?: SurveySessionValueModel;
    question5?: SurveySessionValueModel;
    question6?: SurveySessionValueModel;
    question7?: SurveySessionValueModel;
    question8?: SurveySessionValueModel;
}
