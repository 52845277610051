import { render, staticRenderFns } from "./floating-footer.vue?vue&type=template&id=fb2e1492&scoped=true&"
import script from "./floating-footer.vue?vue&type=script&lang=ts&"
export * from "./floating-footer.vue?vue&type=script&lang=ts&"
import style0 from "./floating-footer.vue?vue&type=style&index=0&id=fb2e1492&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb2e1492",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VFlex,VRow})


/* vuetify-loader */
import installDirectives from "!../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Resize,Scroll})
