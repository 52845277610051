// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/knockout_sumo.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face{font-family:Knockout Sumo;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}*,.application,body,html{font-family:Montserrat,sans-serif!important}h1,h2,h3,h4,h5,h6{font-family:Knockout Sumo,sans-serif!important}.instructions{font-size:1.25rem}.instructions,h1.brand-header{text-align:center;margin:0 0 20px}h1.brand-header{color:\"#0068b3\";font-weight:700;font-size:3em;line-height:1.25}.application a{text-decoration:none}.page-enter-active,.page-leave-active{transition:opacity .3s}.page-enter,.page-leave-to{opacity:.2}.v-btn--floating .v-btn__content,.v-btn--large .v-btn__content,.v-btn--small .v-btn__content{height:inherit;width:inherit}.fa-layers,.fa-stack,.v-icon.fa,.v-icon.svg-inline--fa{display:inline-flex}.v-icon.fa-inverse{color:#fff!important}.svg-inline--fa.v-icon--is-component .v-input{height:16px;font-size:16px;width:16px}.v-input--selection-controls__input .svg-inline--fa.v-icon--is-component{height:24px;font-size:24px;width:24px}.big-btn{width:100%;font-weight:700;font-size:2rem}.v-content__wrap,body{background:#e9f6e5;background:linear-gradient(90deg,#e9f6e5,#d4e1d9 35%,#e9f6e5);overflow:hidden}", ""]);
// Exports
module.exports = exports;
