import Vue from 'vue';
import Vuetify from 'vuetify';
import icons from '~/settings/icons';
import theme from '~/settings/theme';

Vue.use(Vuetify);

export default (context: any, inject: (name: string, value: unknown) => void) => {
    context.app.vuetify = new Vuetify({
        theme,
        icons: {
            iconfont: 'fa',
            values: icons,
        },
    });
    context.$vuetify = context.app.vuetify;
};
