import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d18b1332 = () => interopDefault(import('..\\pages\\coming-soon\\index.vue' /* webpackChunkName: "pages_coming-soon_index" */))
const _14ba703e = () => interopDefault(import('..\\pages\\eligibility\\index.vue' /* webpackChunkName: "pages_eligibility_index" */))
const _c4f15108 = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _cb6e552e = () => interopDefault(import('..\\pages\\failed\\index.vue' /* webpackChunkName: "pages_failed_index" */))
const _2feacb4d = () => interopDefault(import('..\\pages\\not-eligible.vue' /* webpackChunkName: "pages_not-eligible" */))
const _02c34eac = () => interopDefault(import('..\\pages\\patient-information\\index.vue' /* webpackChunkName: "pages_patient-information_index" */))
const _4405535c = () => interopDefault(import('..\\pages\\thank-you\\index.vue' /* webpackChunkName: "pages_thank-you_index" */))
const _fd77d700 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _5b47fdc6 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _5b2bcec4 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _5af370c0 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _5ad741be = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _07423c5e = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _59c13946 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/coming-soon",
    component: _d18b1332,
    name: "coming-soon"
  }, {
    path: "/eligibility",
    component: _14ba703e,
    name: "eligibility"
  }, {
    path: "/error",
    component: _c4f15108,
    name: "error"
  }, {
    path: "/failed",
    component: _cb6e552e,
    name: "failed"
  }, {
    path: "/not-eligible",
    component: _2feacb4d,
    name: "not-eligible"
  }, {
    path: "/patient-information",
    component: _02c34eac,
    name: "patient-information"
  }, {
    path: "/thank-you",
    component: _4405535c,
    name: "thank-you"
  }, {
    path: "/unlock",
    component: _fd77d700,
    name: "unlock"
  }, {
    path: "/errors/400",
    component: _5b47fdc6,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _5b2bcec4,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _5af370c0,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _5ad741be,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _07423c5e,
    name: "errors-500"
  }, {
    path: "/",
    component: _59c13946,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
