import { render, staticRenderFns } from "./DefaultError.vue?vue&type=template&id=c8bb00d2&scoped=true&"
import script from "./DefaultError.vue?vue&type=script&lang=ts&"
export * from "./DefaultError.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8bb00d2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VCard,VCardText,VFlex,VLayout,VSpacer,VToolbarTitle})
