import { Mutation, Action } from 'vuex-module-decorators';
import { Resolve } from 'vue-di';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { EdgePatientEnrollmentApi } from '@trialcard/apigateway.client/edgePatientEnrollmentApi';
import {
    EnrollPatientRequestModel,
    EnterpriseCoordinatorAccountUpsearchRequestModel,
    UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
    EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    EnterpriseCoordinatorSharedAddressUpsearchModel,
    EnterpriseCoordinatorSharedEmailAddressUpsearchModel,
    EnterpriseCoordinatorSharedPhoneUpsearchModel,
    EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel,
    EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel,
} from '@trialcard/apigateway.models';
import { Gender, RoleType } from '@trialcard/enums';

import { Moment } from 'moment';
import { AxiosResponse } from 'axios';
import { EdgeEnrollmentApi } from '@trialcard/apigateway.client';
import { Settings } from '~/services/settings';
import EligibilityModule from '~/store/eligibility';

import SurveyModule from '~/store/survey';

@InjectModule({ stateFactory: true }, module)
export default class EnrollmentModule extends InjectVuexModule {
    @Resolve
    public patientEnrollmentApi!: EdgePatientEnrollmentApi;

    @Resolve
    public enrollmentApi!: EdgeEnrollmentApi;

    @Resolve
    public settings!: Settings;

    @Resolve
    public survey!: SurveyModule;

    @Resolve
    public eligibility!: EligibilityModule;

    alternativeEnrollment: boolean | null = null;

    memberNumber: string | null = null;

    defaultPatientInformationData = {
        firstName: '' as string,
        lastName: '' as string,
        dateOfBirth: null as Moment | string | null,
        addresses: [] as EnterpriseCoordinatorSharedAddressUpsearchModel[],
        emailAddresses: [] as EnterpriseCoordinatorSharedEmailAddressUpsearchModel[],
        phoneNumbers: [] as EnterpriseCoordinatorSharedPhoneUpsearchModel[],
        gender: null as Gender | null,
    };

    defaultParentGuardianData = {
        firstName: '' as string,
        lastName: '' as string,
        phoneNumber: '' as string,
    };

    patientInformationData = { ...this.defaultPatientInformationData };
    parentGuardianData = { ...this.defaultParentGuardianData };

    contactPreferences = [] as EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel[];

    alreadyEnrolled = false as boolean;

    @Mutation
    public async updatePatientInformation(newPatientInformation: typeof EnrollmentModule.prototype.patientInformationData) {
        this.patientInformationData = { ...this.patientInformationData, ...newPatientInformation };
    }

    @Mutation
    public updateParentGuardianInformation(newParentGuardianData: typeof EnrollmentModule.prototype.parentGuardianData) {
        this.parentGuardianData = { ...newParentGuardianData };
    }

    @Mutation
    public setMemberNumber(memberNumber: string | null) {
        this.memberNumber = memberNumber;
    }

    @Mutation
    public setAlreadyEnrolled(newEnrollmentStatus: boolean) {
        this.alreadyEnrolled = newEnrollmentStatus;
    }

    @Action
    public updateAlreadyEnrolled(newEnrollmentStatus: boolean) {
        this.setAlreadyEnrolled(newEnrollmentStatus);
    }

    @Action({ rawError: true })
    public clearEnrollment() {
        this.updatePatientInformation({ ...this.defaultPatientInformationData });
        this.updateParentGuardianInformation({ ...this.defaultParentGuardianData });
        this.updateAlreadyEnrolled(false);
        this.setMemberNumber(null);
    }

    @Action({ rawError: true })
    public async submitToAPI() {
        let response = null;
        try {
            const patient = {
                account: {
                    patientData: {
                        canUpdate: true,
                        canInsert: true,
                        skipSearch: false,
                        model: {
                            ...this.patientInformationData,
                            middleName: '',
                            suffix: '',
                            nickname: '',
                        } as EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
                    } as UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
                    accountData: {
                        canUpdate: true,
                        canInsert: true,
                        skipSearch: false,
                        model: {
                            accountType: 1,
                            accountStatus: 1,
                            contactPreferences: this.contactPreferences,
                            contacts: this.eligibility.formData.isOfAge
                                ? ([
                                      {
                                          roleType: 14 as RoleType, // MAYBE 18 IF PARENT
                                          firstName: this.parentGuardianData.firstName,
                                          lastName: this.parentGuardianData.lastName,
                                          phoneNumber: this.parentGuardianData.phoneNumber.replace(/\D/g, ''),
                                      },
                                  ] as EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel[])
                                : ([] as EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel[]),
                        },
                    } as UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
                    surveySessionData: [this.survey.sessionId] as number[],
                } as EnterpriseCoordinatorAccountUpsearchRequestModel,
            } as EnrollPatientRequestModel;
            response = await this.patientEnrollmentApi.patientEnrollmentEnrollPatient(patient);
        } catch {
            response = { status: 400 } as AxiosResponse;
        }
        return response;
    }
}
