import colors from 'vuetify/es5/util/colors';
import { ThemeOptions } from 'vuetify/types/services/theme';

export default {
    dark: false,
    themes: {
        light: {
            primary: '#51307D',
            secondary: colors.indigo.lighten2,
            accent: colors.deepPurple.lighten2,
            error: colors.red.lighten2,
            warning: colors.amber.lighten2,
            info: colors.lightBlue.lighten2,
            success: colors.green.lighten2,
            black: '#1a1126',
            white: '#FFFFFF',
            purple: '#51307D',
            bluePurple: '#4F5DBF',
            blue: '#4C7ED9',
            green: '#49C5B1',
            pink: '#EAA7C7',
            orange: '#E05510',
            yellow: '#F7B93E',
        },
        dark: {
            primary: colors.blue.darken2,
            secondary: colors.indigo.darken2,
            accent: colors.deepPurple.darken2,
            error: colors.red.darken2,
            warning: colors.amber.darken2,
            info: colors.lightBlue.darken2,
            success: colors.green.darken2,
            black: '#1a1126',
            white: '#FFFFFF',
            purple: '#51307D',
            bluePurple: '#4F5DBF',
            blue: '#4C7ED9',
            green: '#49C5B1',
            pink: '#EAA7C7',
            orange: '#E05510',
            yellow: '#F7B93E',
        },
    },
} as ThemeOptions;
